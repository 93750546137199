import './style.scss';

const lpcbShowPopup = () => {
    const popup = document.getElementsByClassName('lpcb-pop-up');

    if(!popup || popup.length === 0) return;

    [...popup].forEach(elem => {

        const timing = elem.dataset.timing;

        const timingNum = parseInt(timing, 10);

        const timingMs = timingNum * 1000;

        setTimeout(() => {
            elem.classList.add('lpcb-pop-up--is-show')
        }, timingMs);
    })
}

const lpcbHidePopup = () => {
    const btn = document.querySelectorAll('.lpcb-pop-up__close-btn, .lpcb-pop-up__wrapper-background');

    if (!btn || btn.length === 0) return;

    [...btn].forEach(elem => {

        elem.addEventListener('click', () => {
            const popup = document.querySelector('.lpcb-pop-up');

            if (popup) {
                popup.classList.remove('lpcb-pop-up--is-show');
            }
        })
        
    })
}
lpcbHidePopup();

const lpcbShowPopupScrollBlockPosition = () => {
    const trigger = document.getElementsByClassName('wp-block-lpc-blocks-pop-up');

    if (!trigger && trigger.length === 0) return;

    [...trigger].forEach(elem => {
        const observer = new IntersectionObserver((entries) => {
            for (const e of entries) {
                if (e.isIntersecting) {
                    // ウィンドウ内に入った時の処理
                    const popup = elem.querySelector('.lpcb-pop-up');

                    if (!popup.classList.contains('lpcb-pop-up--is-showed')) {
                        popup.classList.add('lpcb-pop-up--is-show');
                        popup.classList.add('lpcb-pop-up--is-showed');
                    }
                }
            }
        }, {});
        observer.observe(elem);
    })

    
}

const lpcbSetPopup = () => {
    const popup = document.getElementsByClassName('lpcb-pop-up');

    if(!popup || popup.length === 0) return;

    [...popup].forEach(elem => {
        const triggerType = elem.dataset.triggerType;

        if (!triggerType) return;

        switch (triggerType) {
            case 'timeout':
                lpcbShowPopup();
                break;

            case 'position':
                lpcbShowPopupScrollBlockPosition();
                break;
        }
    });

    
}
lpcbSetPopup();