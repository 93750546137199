/* eslint-disable no-unused-vars */
import './style.scss';

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const setSwiper = () => {

    const slider = document.querySelectorAll('.lpcb-swiper');

    slider.forEach((elem, index) => {
        const wrapper = elem.querySelector('.swiper-wrapper');
        const hasNavigation = wrapper.dataset.navigation && wrapper.dataset.navigation === 'true' ? true : false;
        const hasPagination = wrapper.dataset.pagination && wrapper.dataset.pagination === 'true' ? true : false;
        const autoPlay = wrapper.dataset.autoPlay && wrapper.dataset.autoPlay === 'true' ? true : false;
        const loop = wrapper.dataset.loop && wrapper.dataset.loop === 'true' ? true : false;
        let speed = wrapper.dataset.speed ? wrapper.dataset.speed : '0.3';
        let delay = wrapper.dataset.delay ? wrapper.dataset.delay : '4';
        const slidePerView = Number(wrapper.dataset.num ? wrapper.dataset.num : '1');
        const slidePerViewTablet = Number(wrapper.dataset.numTablet ? wrapper.dataset.numTablet : '1');
        const slidePerViewSp = Number(wrapper.dataset.numSp ? wrapper.dataset.numSp : '1');
        const spaceBetween = Number(wrapper.dataset.space ? wrapper.dataset.space : '10');
        const spaceBetweenTablet = Number(wrapper.dataset.spaceTablet ? wrapper.dataset.spaceTablet : '10');
        const spaceBetweenSp = Number(wrapper.dataset.spaceSp ? wrapper.dataset.spaceSp : '10');

        speed = Number(speed) * 1000;
        delay = Number(delay) * 1000;

        const className = 'lpcb-swiper-' + index;
        elem.classList.add(className);

        const classNameSelector = '.' + className;

        const setting = {
            modules: [Navigation, Pagination, Autoplay],
            breakpoints: {
                992: {},
                768: {},
            }
        }

        if (loop) {
            setting.loop = loop;
        }

        if (hasNavigation) {
            setting.navigation = {
                nextEl: '.lpcb-swiper-button-next',
                prevEl: '.lpcb-swiper-button-prev',
            }
        }

        if (hasPagination) {
            setting.pagination = {
                el: '.lpcb-swiper-pagination',
                clickable: true,
            }
        }

        if (autoPlay) {
            setting.autoplay = {
                delay: delay,
            }
        }

        if (speed) {
            setting.speed = speed;
        }

        if (slidePerViewSp) {
            setting.slidesPerView = slidePerViewSp;

            if (slidePerViewSp >= 2) {
                setting.loop = false;
            }
        }

        if (slidePerViewTablet) {
            setting.breakpoints[768].slidesPerView = slidePerViewTablet;

            if (slidePerViewTablet >= 2) {
                setting.breakpoints[768].loop = false;
            }
        }

        if (slidePerView) {
            setting.breakpoints[992].slidesPerView = slidePerView;

            if (slidePerView >= 2) {
                setting.breakpoints[992].loop = false;
            }
        }

        if (spaceBetweenSp || spaceBetweenSp === 0) {
            setting.spaceBetween = spaceBetweenSp;
        }

        if (spaceBetweenTablet || spaceBetweenTablet === 0) {
            setting.breakpoints[768].spaceBetween = spaceBetweenTablet;
        }

        if (spaceBetween || spaceBetween === 0) {
            setting.breakpoints[992].spaceBetween = spaceBetween;
        }

        

        const swiper = new Swiper(classNameSelector, setting);
    })
}
window.onload = setSwiper();