import './style.scss';
import jQuery from 'jquery';

jQuery(function($) {
    $('.lpcb-faq-list--accordion .lpcb-faq__q').on('click', function() {

        $(this).next().slideToggle(300, 'linear');

        $(this).toggleClass('is-open');
    })
})