/* eslint-disable no-undef */
import './style.scss';
import Cookies from 'js-cookie';

const lpcbStartCountDown = (end) => {

	let timerId;

	const block = document.querySelectorAll('.lpcb-count-down-timer');

	const countdown = (end, dateElem, hourElem, minElem, secElem, msecElem) => {		
		const now = new Date();

		if (end.getTime() <= now.getTime()) {
			clearInterval(timerId);
		}

		const diff = end.getTime() - now.getTime(); // 時間の差を取得（ミリ秒）

		// ミリ秒から単位を修正
		const calcHour = Math.floor(diff / 1000 / 60 / 60);
		const calcDate = Math.floor(calcHour / 24);
		const calcMin = Math.floor(diff / 1000 / 60) % 60;
		const calcSec = Math.floor(diff / 1000) % 60;
		const calcMSec = String(Math.floor(diff)).slice(-3, -1);

		// 取得した時間を表示（2桁表示）
		dateElem.innerHTML = calcDate;
		hourElem.innerHTML = calcHour % 24;
		minElem.innerHTML = calcMin < 10 ? '0' + calcMin : calcMin;
		secElem.innerHTML = calcSec < 10 ? '0' + calcSec : calcSec;
		msecElem.innerHTML = calcMSec;
	}

	block.forEach(elem => {
		const dateElem = elem.querySelector('.js-lpcb-count-down-timer__d');
		const hour = elem.querySelector(".js-lpcb-count-down-timer__h");
		const min = elem.querySelector(".js-lpcb-count-down-timer__m");
		const sec = elem.querySelector(".js-lpcb-count-down-timer__s");
		const msec = elem.querySelector(".js-lpcb-count-down-timer__ms");

		timerId = setInterval(() => {
			countdown(end, dateElem, hour, min, sec, msec);
		}, 20);
	});
}

const lpcbCountDownTimer = () => {

	const blocks = document.querySelectorAll('.lpcb-count-down-timer');

	if(!blocks || blocks.length === 0) return;

	if (typeof lpcbFrontJsValue === 'undefined' || !lpcbFrontJsValue) return;

	let isFirstVisit = true;

	const countDownSetting = lpcbFrontJsValue.countdownSettings;

	if(!countDownSetting || countDownSetting.length === 0) return;

	if(countDownSetting.type === 'elapsed') {
		
		if(!countDownSetting.elapsedTime) return;
		const postId = lpcbFrontJsValue.postId;
		const elapsedTime = Number(countDownSetting.elapsedTime);
		const accessDatetime = Cookies.get(`_lpcb_access_datetime_${postId}`);

		if (accessDatetime) {
			const end = new Date(accessDatetime);

			end.setHours(end.getHours() + elapsedTime);
			lpcbStartCountDown(end);
			isFirstVisit = false;
		}

		if(isFirstVisit) {

			const end = new Date();

			end.setHours(end.getHours() + elapsedTime);

			lpcbStartCountDown(end);
		}
	} else {
		if(!countDownSetting.finishDatetime) return;

		const finishDatetime = countDownSetting.finishDatetime;

		const end = new Date(finishDatetime);

		lpcbStartCountDown(end);
	}
}
lpcbCountDownTimer();

const lpcbSaveAccessDateTime = () => {

	const blocks = document.querySelectorAll('.lpcb-count-down-timer');

	if(!blocks || blocks.length === 0) return;

	if (typeof lpcbFrontJsValue === 'undefined' || !lpcbFrontJsValue) return;
	const postId = lpcbFrontJsValue.postId;

	const accessDatetime = Cookies.get(`_lpcb_access_datetime_${postId}`);

	if (!accessDatetime) {
		const now = new Date();
		
		const year = now.getFullYear();
		const month = (now.getMonth() + 1).toString().padStart(2, "0");
		const date = now.getDate().toString().padStart(2, "0");
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		const nowDatetime = `${year}-${month}-${date} ${hours}:${minutes}:00`;
		Cookies.set(`_lpcb_access_datetime_${postId}`, `${nowDatetime}`, { expires: 30, path: '/' });
	}
}
lpcbSaveAccessDateTime();
